import React, { useState } from 'react';
import { CheckboxLineAnnotationType, ILinesAndAnnotations, checkboxNames } from '../../common/helper';
import { StockSignalsEnum } from 'predictagram-lib';

const Checkbox: React.FunctionComponent<{
  name: string,
  checked: boolean,
  label: string,
  onChange: (key: string, val: boolean) => void
}> = ({ name, checked, label, onChange }) => {

  const [currentChecked, setCurrentChecked] = useState<boolean>(checked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentChecked(e.target.checked);
    onChange(name, e.target.checked);
  };

  return (
    <div className="d-flex gap-1 align-items-center">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={currentChecked}
        onChange={handleChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

export const LinesAndOptions: React.FunctionComponent<{
  linesAndAnnots: ILinesAndAnnotations,
  setLinesAndAnnots: (v: ILinesAndAnnotations) => void,
}> = ({
  linesAndAnnots,
  setLinesAndAnnots,
 }) => {

  const currentLinesAndAnnots = linesAndAnnots;

  const renderCheckbox = (item: { [key: string]: boolean; }, index: number, array: CheckboxLineAnnotationType[]) => {
    const entries = Object.entries(item);
    return (
      <div key={`key-${index}`} className="text-12">
        {entries.map(([key, value], ci) => {
          return (
              <Checkbox key={ci} label={checkboxNames.get(key as StockSignalsEnum) || key} checked={value} name={`${key}`} onChange={(key, value) => {
                item[key] = value;
                setLinesAndAnnots({
                  ...currentLinesAndAnnots,
                })
              }} />
          )
        })}
      </div>
    );
  }

  const render = () => {
    return (
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-columns gap-2">
          <div className="d-flex flex-row gap-2">
            <div>
              {currentLinesAndAnnots.lines.map(renderCheckbox)}
            </div>
            <div>
              {currentLinesAndAnnots.lastNHour.map(renderCheckbox)}
            </div>
          </div>


          <div>
            {currentLinesAndAnnots.chartOptions.map(renderCheckbox)}
            {currentLinesAndAnnots.extraLines.map(renderCheckbox)}
          </div>
        </div>

      </div>

    );
  }

  return (
    <div className="d-flex gap-3">
      {render()}
    </div>
  )
}
